<template>
  <div v-if="respData">
    <b-row
      v-if="$ability.can('read', this.$route.meta.resource)"
      class="match-height"
    >
      <b-col md="12">
        <b-card no-body>
          <b-card-header header-tag="nav">
            <div class="w-100">

              <b-card-text class="float-left mb-0">
                White list
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mr-2"
                @click="add()"
              >{{ $t('Add') }}</b-button>
            </div>
          </b-card-header>
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ 'Index' }}
                    </b-td>
                    <b-td>{{ 'Url' }}</b-td>
                    <b-td>{{ 'Action' }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(item, index) in lists"
                    :key="`${index}`"
                  >
                    <b-td>{{ index + 1 }}</b-td>
                    <b-td>
                      <b-form-input
                        v-if="lists[index]"
                        :value="lists[index]['url']"
                        @input="inputUrl($event, index,)"
                      />
                    </b-td>
                    <b-td>
                      <b-button
                        v-if="lists.length>1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        size="sm"
                        class=""
                        @click="deleteItem(index)"
                      >{{ $t('Delete') }}</b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-button
            size="sm"
            variant="primary"
            @click="save()"
          >
            <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BButton,
  BCard,
  BCardHeader,
  BNav,
  BNavItem,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/root/webviewWhitelist'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'webviewWhitelist'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    vSelect,
    BButton,
    BCard,
    BCardHeader,
    BNav,
    BNavItem,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      lists: [{
        url: '',
      }],
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    this.get()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    add() {
      this.lists.push({
        url: '',
      })
    },
    deleteItem(index) {
      this.lists.splice(index, 1)
    },
    inputUrl(input, index) {
      this.lists[index].url = input
    },
    save() {
      store
        .dispatch(`${STORE_MODULE_NAME}/save`, { lists: this.lists })
        .then(result => {

        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    get() {
      store
        .dispatch(`${STORE_MODULE_NAME}/get`)
        .then(result => {
          const { data } = result.data
          this.lists = data.map(e => ({
            url: e.url,
          }))
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
