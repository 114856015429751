import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
  },
  actions: {
    get({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/web/root/webviewWhitelist/get')
          .then(response => {
            commit('GET', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    save({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('api/web/root/webviewWhitelist/save', item)
          .then(response => {
            // commit('GET', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
